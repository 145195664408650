import { IBooking } from '@/services/types/booking/details';

import { EProjectSection, trackEventV2 } from '../trackEventV2';
import {
  EBookingManagementEventName,
  IBookingManagementEvent,
  IDriverInvited,
  IGuestInvited,
  IRenterBookingAdditionalProtectionAdded,
  IRenterBookingAdditionalProtectionStarted,
  IRenterBookingViewedEvent,
} from './types';

export const protectionTypeEventMap: { [key: string]: string } = {
  'damage-protection': 'interior_damage_protection',
  'trip-insurance': 'trip_insurance',
  weather: 'weather_insurance',
};

/**
 * TRACKING FUNCTIONS
 */
export function trackRenterBookingSelected(event: IBookingManagementEvent) {
  trackEventV2(EBookingManagementEventName.RENTER_BOOKING_SELECTED, EProjectSection.MARKETPLACE, {
    ...event,
  });
}

export function trackRenterBookingViewed(event: IRenterBookingViewedEvent) {
  trackEventV2(EBookingManagementEventName.RENTER_BOOKING_VIEWED, EProjectSection.MARKETPLACE, {
    ...event,
  });
}

export function trackRenterBookingAdditionalProtectionsStarted(
  event: IRenterBookingAdditionalProtectionStarted,
) {
  trackEventV2(
    EBookingManagementEventName.RENTER_BOOKING_ADDITIONAL_PROTECTION_STARTED,
    EProjectSection.MARKETPLACE,
    {
      ...event,
    },
  );
}

export function trackRenterBookingAdditionalProtectionsAdded(
  event: IRenterBookingAdditionalProtectionAdded,
) {
  trackEventV2(
    EBookingManagementEventName.RENTER_BOOKING_ADDITIONAL_PROTECTION_ADDED,
    EProjectSection.MARKETPLACE,
    {
      ...event,
    },
  );
}

export function getAdditionalProtectionsTrackInfo(booking: Partial<IBooking>) {
  if (!booking?.services) return null;
  const selectedProtections = booking.services.filter(
    ({ category }) => category in protectionTypeEventMap,
  );

  const protectionsSelected = selectedProtections
    ?.map(({ category }) => protectionTypeEventMap[category])
    .filter((p): p is string => !!p);

  const protectionsCost = selectedProtections.reduce((acc, next) => acc + next.price, 0);

  return {
    protectionsCost,
    protectionsSelected,
  };
}

export function trackDriverInvited(event: IDriverInvited) {
  trackEventV2(EBookingManagementEventName.DRIVER_INVITED, EProjectSection.MARKETPLACE, {
    ...event,
  });
}

export function trackGuestInvited(event: IGuestInvited) {
  trackEventV2(EBookingManagementEventName.GUEST_INVITED, EProjectSection.MARKETPLACE, {
    ...event,
  });
}
