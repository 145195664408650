import { BookingStatus } from '@/services/types/booking/details';

import { ERentalType } from '../types';

export enum EBookingManagementEventName {
  RENTER_BOOKING_SELECTED = 'Renter Booking Selected',
  RENTER_BOOKING_VIEWED = 'Renter Booking Viewed',
  RENTER_BOOKING_ADDITIONAL_PROTECTION_STARTED = 'Additional Protection Started',
  RENTER_BOOKING_ADDITIONAL_PROTECTION_CONTINUED = 'Additional Protection Continued',
  RENTER_BOOKING_ADDITIONAL_PROTECTION_ADDED = 'Additional Protection Added',
  DRIVER_INVITED = 'Driver Invited',
  GUEST_INVITED = 'Guest Invited',
}

/**
 * INTERFACES
 */
export interface IBookingManagementEvent {
  bookingID: number;
  bookingDepartureDate: number;
  bookingReturnDate: number;
  isDelivery: boolean;
  bookingLocation: string;
  tripStatus: BookingStatus;
  rentalType: ERentalType;
  isCampground: boolean;
}

export interface IRenterBookingViewedEvent extends IBookingManagementEvent {
  totalDrivers: number;
  totalVerifiedDrivers: number;
  insuranceCoverage: string | null;
  protectionType: string[] | null;
  totalAttendees: number;
  cancellationActor: string | null;
  changesForReview: string | null;
}

export interface IRenterBookingAdditionalProtectionStarted {
  bookingID: number;
  protectionsSelected: string[] | null;
  protectionsCost: number;
  rentalType: ERentalType;
  isCampground: boolean;
}

export interface IRenterBookingAdditionalProtectionAdded
  extends IRenterBookingAdditionalProtectionStarted {
  paymentMethod: string | null;
}

export enum EBookingManagementEventSource {
  STATUS_CARD = 'status_card',
  GUESTS = 'guests',
}

export interface IDriverInvited {
  bookingID: number;
  source: EBookingManagementEventSource;
  totalDrivers: number;
  totalVerifiedDrivers: number;
  rentalType: ERentalType;
}

export interface IGuestInvited {
  bookingID: number;
  totalAttendees: number;
  source: EBookingManagementEventSource;
  rentalType: ERentalType;
  isCampground: boolean;
}
