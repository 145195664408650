import he from 'he';
import Head from 'next/head';
import { StaticImageData } from 'next/image';
import React from 'react';
import { useIntl } from 'react-intl';

import homepageThumbnail from '@/assets/home/homepage-social-media-thumbnail.jpg';

interface IHeadMetaProps {
  title?: string;
  description?: string;
  url?: string;
  canonicalUrl?: string;
  noindex?: boolean;
  nofollow?: boolean;
  thumbnailURL?: string | StaticImageData;
  hideOutdoorsyTitle?: boolean;
}

const HeadMeta: React.FC<React.PropsWithChildren<IHeadMetaProps>> = ({
  title: preferredTitle,
  description: preferredDescription,
  url,
  canonicalUrl,
  noindex,
  nofollow,
  children,
  thumbnailURL: preferredThumbnail,
  hideOutdoorsyTitle,
}) => {
  const intl = useIntl();
  const defaultDescription = intl.formatMessage({
    defaultMessage:
      'Find RV Rentals Near you on Outdoorsy. The only person-to-person RV rental marketplace in the US',
    id: 'qz1auY',
  });

  const title =
    preferredTitle ||
    intl.formatMessage({
      defaultMessage: 'RV Rentals: Explore the Open Road in Comfort',
      id: 'LQBwbZ',
    });
  const description = preferredDescription || defaultDescription;
  const thumbnailURL = preferredThumbnail || homepageThumbnail.src;
  const thumbnailSrc = typeof thumbnailURL !== 'string' ? thumbnailURL.src : thumbnailURL;

  let robotsContent = 'max-image-preview:large';
  if (noindex && nofollow) {
    robotsContent = 'none';
  } else if (noindex) {
    robotsContent = 'noindex';
  }

  let computedTitle = 'Outdoorsy';
  if (title) {
    if (hideOutdoorsyTitle) {
      // eslint-disable-next-line import/no-named-as-default-member
      computedTitle = `${he.decode(title)}`;
    } else {
      // eslint-disable-next-line import/no-named-as-default-member
      computedTitle = `${he.decode(title)} | Outdoorsy`;
    }
  }

  return (
    <Head>
      <title>{computedTitle}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      <meta property="fb:app_id" content="604465049688341" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {url && <meta property="og:url" content={url} />}
      <meta property="og:site_name" content="Outdoorsy" />
      <meta property="og:image" content={thumbnailSrc} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@outdoorsy" />
      <meta name="twitter:creator" content="@outdoorsy" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={thumbnailSrc} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      <meta name="robots" content={robotsContent} />
      {children}
    </Head>
  );
};

export default HeadMeta;
