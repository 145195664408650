import { ETagVariant, Tag } from '@outdoorsyco/bonfire';
import React from 'react';

import { BookingConfirmationStatus, BookingStatus } from '@/services/types/booking/details';

interface IBookingStatusLabelProps {
  status: BookingStatus | BookingConfirmationStatus;
  statusDisplay: string;
}

export const BookingStatusLabel: React.FC<IBookingStatusLabelProps> = ({
  status,
  statusDisplay,
}) => {
  if (!status) return null;

  if (
    [
      'expired',
      'owner_cancelled',
      'renter_withdrew',
      'renter_cancelled',
      'owner_declined',
    ].includes(status)
  ) {
    return (
      <Tag
        variant={ETagVariant.Critical}
        label={statusDisplay}
        role="status"
        ariaLabel={statusDisplay}
      />
    );
  }
  if (status === 'negotiating' || status === 'pending') {
    return (
      <Tag
        variant={ETagVariant.Attention}
        label={statusDisplay}
        role="status"
        ariaLabel={statusDisplay}
      />
    );
  }
  return (
    <Tag
      variant={ETagVariant.Success}
      label={statusDisplay}
      role="status"
      ariaLabel={statusDisplay}
    />
  );
};
