import { clsx } from 'clsx';
import React from 'react';

import { IBooking } from '@/services/types/booking/details';

import BookingCard from './BookingCard';

interface IBookingCardContainerProps {
  bookings: IBooking[];
  isNewHomepage?: boolean;
}

const BookingCardContainer: React.FC<React.PropsWithChildren<IBookingCardContainerProps>> = ({
  bookings,
  children,
  isNewHomepage = false,
}) => {
  if (!bookings?.length) return null;

  return (
    <div
      className={clsx('mx-4 mb-8 md:mx-0', {
        'mb-0 bg-white !mx-0 rounded-2xl': isNewHomepage,
      })}>
      <div
        className={clsx('grid grid-cols-1 gap-x-12 gap-y-2 sm:gap-y-8', {
          'md:gap-x-0': isNewHomepage,
          'xl:grid-cols-2': !isNewHomepage,
        })}>
        {bookings.map(trip => (
          <BookingCard key={trip.id} booking={trip} isNewHomepage={isNewHomepage} />
        ))}
      </div>
      {children}
    </div>
  );
};

export default BookingCardContainer;
