import { ThunkAction } from 'redux-thunk';

import { EBookingsFilters } from '@/constants/booking';
import * as BookingsActions from '@/redux/modules/bookings/type';
import { TRootState } from '@/redux/rootReducer';
import apiRequest from '@/services/apiRequest';
import type { IBooking } from '@/services/types/booking/details';
import { getCoreApi } from '@/utility/getCoreApi';
import { getAuthToken } from '@/utility/session';

const FETCH_BOOKINGS_URL = `${getCoreApi()}/bookings/`;

export interface IBookingsResponse {
  bookings: IBooking[];
  filter: EBookingsFilters;
}

//fetch Bookings actions
const fetchBookingsPendingAction = (filter: EBookingsFilters): BookingsActions.IBookingsAction => {
  return {
    type: BookingsActions.FETCH_BOOKINGS_PENDING,
    payload: { filter },
  };
};

const fetchBookingsFailureAction = (
  filter: EBookingsFilters,
  error: boolean,
): BookingsActions.IBookingsAction => {
  return {
    type: BookingsActions.FETCH_BOOKINGS_FAILURE,
    payload: { filter },
    error,
  };
};

const fetchBookingsSuccessAction = (
  bookings: IBooking[],
  filter: EBookingsFilters,
): BookingsActions.IBookingsAction => {
  return {
    type: BookingsActions.FETCH_BOOKINGS_SUCCESS,
    payload: { bookings, filter },
  };
};

export const fetchBookings =
  (
    filter: EBookingsFilters,
    locale: string,
    limit?: string,
  ): ThunkAction<void, TRootState, void, BookingsActions.IBookingsAction> =>
  async dispatch => {
    dispatch(fetchBookingsPendingAction(filter));
    const authToken = getAuthToken();
    if (!authToken) {
      dispatch(fetchBookingsFailureAction(filter, true));
      return;
    }

    const params = {
      filter,
      include_shared: true,
      role: 'renter',
      locale: locale,
      deliverable_campground: true,
    } as Record<string, string | boolean>;

    if (limit) {
      params.limit = limit;
    }

    try {
      const booking = await apiRequest<IBooking[]>(
        {
          url: FETCH_BOOKINGS_URL,
          method: 'GET',
          params,
        },
        true,
      );
      if (booking) {
        dispatch(fetchBookingsSuccessAction(booking, filter));
      } else {
        dispatch(fetchBookingsFailureAction(filter, true));
      }
    } catch {
      dispatch(fetchBookingsFailureAction(filter, true));
    }
  };
