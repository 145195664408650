import dayjs from 'dayjs';
import { FormatDateOptions, useIntl } from 'react-intl';

export function useFormattedDate() {
  const intl = useIntl();

  // using intl.format for a date like 2023-06-27
  // without wrapping it with dayjs could cause strange behavior in some timezones
  // where instead of parsing it as June 27 it may subtract a day (to June 26)
  return (date: string | number | undefined, options: FormatDateOptions) =>
    intl.formatDate(dayjs(date).toDate(), options);
}
